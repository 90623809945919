import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import {Link, Typography} from "@mui/material";
import {useMediaQuery} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import {Grid} from "@mui/material";

const AIAPrivacyLink = "https://www.aia.com.au/en/individual/index/privacy-policy.html";

const custom_disclaimer = process.env.REACT_APP_CUSTOM_DISCLAIMER ? process.env.REACT_APP_CUSTOM_DISCLAIMER : "NO";
const custom_disclaimer_text = process.env.REACT_APP_CUSTOM_DISCLAIMER_TEXT ? process.env.REACT_APP_CUSTOM_DISCLAIMER_TEXT : '';
const custom_policy_link_text = process.env.REACT_APP_CUSTOM_POLICY_LINK_TEXT ? process.env.REACT_APP_CUSTOM_POLICY_LINK_TEXT : ' AIA Health Privacy Statement';
const custom_policy_link = process.env.REACT_APP_POLICY_LINK ? process.env.REACT_APP_POLICY_LINK : 'https://www.aia.com.au/en/privacy-policy'
const custom_telephone_number = process.env.REACT_APP_TELEPHONE_NUMBER ? process.env.REACT_APP_TELEPHONE_NUMBER : '+61 3 7065 8908'

export default function BottomBar() {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box sx={{position: 'fixed', bottom: 0, width: '100%'}}>
            <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
                sx={{height: "auto"}}
            >
                <Grid style={{padding: "2rem"}}>
                {matches ?
                <Box gridColumn="span 12">
                    <Typography variant="p" style={{display: 'block', textAlign: "center"}}> Any problems with this form please contact: </Typography>
                    <Typography variant="p" style={{display: "block", textAlign: "center"}}> <span style={{color: "red"}}> +61 3 7065 8908  </span> </Typography>
                </Box> :

                    <Box gridColumn="span 12">

                        {custom_disclaimer === "NO"  &&

                            <React.Fragment>

                                <Typography variant="p" style={{display: 'block', textAlign: "center"}}> Any problems with
                                    this form please contact: <span style={{color: "red"}}> {custom_telephone_number}  </span>
                                </Typography>
                                <Typography variant="p"  style={{display: 'block', textAlign: "center"}}> Your information is
                                important to us. For more information on how we collect, store, use and handle your information,
                                please read the <span style={{color: "red"}}> <Link target="_blank" color="inherit" underline="none" href={AIAPrivacyLink}> AIA Health Privacy Statement </Link> </span> </Typography>

                            </React.Fragment>

                        }

                        {custom_disclaimer === "YES"  &&

                        <React.Fragment>

                            <Typography variant="p" style={{display: 'block', textAlign: "center"}}> Any problems with
                                this form please contact: <span style={{color: "red"}}> {custom_telephone_number}  </span>
                            </Typography>
                            <Typography variant="p"  style={{display: 'block', textAlign: "center"}}> {custom_disclaimer_text} <span style={{color: "red"}}> <Link target="_blank" color="inherit" underline="none" href={custom_policy_link}> {custom_policy_link_text} </Link> </span> </Typography>

                        </React.Fragment>

                        }

                    </Box>
                }
                </Grid>



            </BottomNavigation>
            <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
                sx={{
                    backgroundColor: "#2d2926",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50px"
                }}
            >
                <Typography variant="p" style={{display: 'block', textAlign: "center", color: "white"}}>
                    <span style={{color: "#DADADA"}}> Term of Use </span>
                    <span style={{marginLeft: "2px", marginRight: "2px"}}>|</span>
                    <span style={{color: "#DADADA"}}>
                        <Link target="_blank" color="inherit" underline="none"
                                                      href={custom_policy_link}> {custom_policy_link_text}
                        </Link>
                    </span>
                </Typography>

            </BottomNavigation>
        </Box>
    );
}
